import { axiosInstance } from "../../axios";

const GETAnalyticPendingRegistration = `/api/Analytic/AnalyticPendingRegistration`;
const GETAnalyticActiveRegistration = `/api/Analytic/AnalyticActiveRegistration`;
const GETAnalyticProcessingRegistration = `/api/Analytic/AnalyticProcessingRegistration`;
const GETAnalyticBlacklistVehicle = `/api/BlacklistVehicle/SearchCurrentBlacklist`;
const GETAnalyticsCustomerCountByPostcode = `/api/Analytic/AnalyticsCustomerCountByPostcode`;
const GETAnalyticsSeasonPassByPostcode = `/api/Analytic/AnalyticsSeasonPassByPostcode/`;
const GETAnalyticPromoCode = `/api/Analytic/AnalyticsPromoCode`;
const POSTVisitByTimeOfDay = `/api/ReportVisitation/ChartVisitationByTimeOfDay`;
const POSTVisitByDate = `/api/ReportVisitation/ChartVisitationByDate`;

const searchChartVisitsByTime = async function(chartParam) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTVisitByTimeOfDay, chartParam);
        let data = parseList(response);
        return data;
    } catch (error) {
        return null;
    }
};

const searchChartVisitByDate = async function(chartParam) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTVisitByDate, chartParam);
        let data = parseList(response);
        return data;
    } catch (error) {
        return null;
    }
};

const searchAnalyticPendingRegistration = async function(id) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETAnalyticPendingRegistration);
        let data = parseList(response);
        return data;
    } catch (error) {
        return null;
    }
};

const searchAnalyticActiveRegistration = async function(id) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETAnalyticActiveRegistration);
        let data = parseList(response);
        return data;
    } catch (error) {
        return null;
    }
};

const searchAnalyticProcessingRegistration = async function(id) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETAnalyticProcessingRegistration);
        let data = parseList(response);
        return data;
    } catch (error) {
        return null;
    }
};

const searchAnalyticBlacklist = async function(id) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETAnalyticBlacklistVehicle);
        let data = parseList(response);
        return data;
    } catch (error) {
        return null;
    }
};

const searchAnalyticsCustomerCountByPostcode = async function(id) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETAnalyticsCustomerCountByPostcode);
        let data = parseList(response);
        return data;
    } catch (error) {
        return null;
    }
};

const searchAnalyticsSeasonPassByPostcode = async function(id) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETAnalyticsSeasonPassByPostcode + id);
        let data = parseList(response);
        return data;
    } catch (error) {
        return null;
    }
};

const searchPromoCode = async function(id) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETAnalyticPromoCode);
        let data = parseList(response);
        return data;
    } catch (error) {
        return null;
    }
};

const parseList = response => {
    if (response.status === 204) throw Error("Please Select Company");
    if (response.status !== 200) throw Error(response.message);

    if (!response.data) return [];
    let list = response.data;
    if (typeof list !== "object") {
        list = [];
    }
    return list;
};

export const analyticsData = {
    searchAnalyticPendingRegistration,
    searchAnalyticActiveRegistration,
    searchAnalyticBlacklist,
    searchAnalyticProcessingRegistration,
    searchAnalyticsCustomerCountByPostcode,
    searchAnalyticsSeasonPassByPostcode,
    searchPromoCode,
    searchChartVisitsByTime,
    searchChartVisitByDate
};
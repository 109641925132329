<template>
<vx-card title="Customers" customTitle="By Product">
    <div slot="actions" class="flex justify-center">
        <vs-select name="status" v-model="selectedProductId" @change="loadChartData" class="mr-5">
            <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item, index) in productList" />
        </vs-select>
        <vs-tooltip text="Export to Excel" position="bottom">
            <vs-button icon-pack="feather" icon="icon-download" color="success" @click="downloadexcel"></vs-button>
        </vs-tooltip>
    </div>
    <vue-apex-charts v-if="this.chartDisplay" class="mt-6 mb-8" :key="componentKey" type="donut" height="200" :options="computedChartData.chartOptions" :series="computedChartData.series" />
    <NoData v-else class="mt-base" :headTitle="noData.title" :description="noData.description"></NoData>
</vx-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import {
    analyticsData
} from "@/store/api/analytics";

import {
    productData
} from "@/store/api/product";
import {
    reportsData
} from "@/store/api/report";

export default {
    components: {
        VueApexCharts
    },
    data() {
        return {
            componentKey: 1,
            chartData: [],
            chartSeries: [],
            chartLabel: [],
            productList: [],
            selectedProductId: '',
            chartDisplay: false,
            noData: {
                title: "No Records Found",
            },
        }
    },
    computed: {
        computedChartData() {
            const columnChart = {
                series: this.chartSeries,
                chartOptions: {
                    labels: this.chartLabel,
                    dataLabels: {
                        enabled: false
                    },
                    legend: {
                        show: true
                    },
                    chart: {
                        //offsetY: 30,
                        type: 'donut',
                        toolbar: {
                            show: false
                        }
                    },
                    stroke: {
                        width: 0
                    },
                    colors: ['#7961F9', '#FF9F43', '#EA5455'],
                    fill: {
                        type: 'gradient',
                        gradient: {
                            gradientToColors: ['#9c8cfc', '#FFC085', '#f29292']
                        }
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            // legend: {
                            //   position: 'bottom'
                            // }
                        }
                    }]
                }
            }

            return columnChart;
        }
    },
    async created() {
        await this.productData();
    },
    methods: {
        async productData() {
            this.productList = await productData.SearchAllProducts();
            if (this.productList) {
                this.selectedProductId = this.productList[0].id;
            }
        },
        async getChartData() {
            this.chartData = await analyticsData.searchAnalyticsSeasonPassByPostcode(this.selectedProductId);
            if (this.chartData) {
                await this.loadChartData();
            }
        },
        async loadChartData() {
            this.componentKey + 1;
            this.chartData = await analyticsData.searchAnalyticsSeasonPassByPostcode(this.selectedProductId);
            if (this.chartData.length > 0) {
                this.chartDisplay = true,
                    this.chartSeries = [];
                this.chartLabel = [];
                this.chartData.forEach(element => {
                    this.chartSeries.push(element.preferenceCount)
                    this.chartLabel.push(element.preference)
                });
            } else {
                this.chartDisplay = false
            }
        },
        async downloadexcel() {
            const reportParameter = {
                productId: this.selectedProductId
            };

            let data = await reportsData.seasonPassByPostcodeReportExportProduct(reportParameter);
            window.open(data.url, '_blank').focus();
        },
    }
}
</script>

<template>
<vx-card title="Promo Codes" class="mt-base">
    <div class="flex justify-between">
        <div class="flex flex-col">
            <h6 class="mb-1">Redeemed Codes</h6>
            <h4>{{ percentageUsed }}% ({{ promoCodeUsed }})</h4>
        </div>
        <div class="flex flex-col text-right">
            <span class="flex -mr-1">
                <h6>Total: {{ promoCodeTotal }}</h6>
            </span>
        </div>
    </div>
    <vs-progress :percent="percentageUsed"></vs-progress>
</vx-card>
</template>

<script>
import {
    analyticsData
} from "@/store/api/analytics";

export default {
    data() {
        return {
            widgetPromoCode: null,
        }
    },
    computed: {
        promoCodeTotal() {
            if (this.widgetPromoCode) {
                var total_sum = this.widgetPromoCode.reduce(function (prev, cur) {
                    return prev + cur.countOfPromo;
                }, 0.00);
                return total_sum;
            }
            return 0.00;
        },
        promoCodeUsed() {
            if (this.widgetPromoCode) {
                var used = this.widgetPromoCode.filter(x => x.status === "Redeemed");
                if (used) {
                    return used[0].countOfPromo;
                }
            }
            return 0.00;
        },
        percentageUsed() {
            var result = (this.promoCodeUsed * 100.00) / this.promoCodeTotal;
            return result.toFixed(2);
        }
    },
    async created() {
        await this.loadPromoCodeReport();
    },
    methods: {
        async loadPromoCodeReport() {
            this.widgetPromoCode = await analyticsData.searchPromoCode();
            if (this.widgetPromoCode) {

            }
        },
    }
}
</script>
